nav {
  background-color: rgb(43, 47, 49);
  width: 100%;
  max-width: 1400px;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 10;
}

nav a {
  text-decoration: none;
  z-index: 100;
}

.nav_icon {
  padding: 8px;
}

.nav-links {
  display: inline-block;
  text-align: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.nav-link {
  list-style: none;
  display: inline-block;
  margin: 0px 8px 0px 8px;
  padding: 6px;
  cursor: pointer;
  font-weight: bold;
}

.nav-link:hover {
  color: rgb(117, 185, 130);
}

@media only screen and (max-width: 700px) {
  .nav-links {
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    margin: 0px;
    margin-bottom: 4px;
  }
  li {
    width: 100vw;
    display: block;
    animation: showLink 0.3s linear;
    background-color: transparent;
  }
  .nav-links.active {
    position: absolute;
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: rgb(43, 47, 49);
  }
}

@keyframes showLink {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
