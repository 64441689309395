.services {
  padding: 60px 20px;
}

.icon {
  font-size: 30px;
  margin-bottom: 6px;
  color: rgb(117, 185, 130);
}

.services h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.services p {
  text-align: center;
}

.services-list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.service-card {
  background-color: rgba(91, 99, 104, 0.178);
  border-radius: 8px;
  padding: 30px;
  width: 330px;
  min-width: 280px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.service-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: left;
}

@media only screen and (max-width: 900px) {
  .service-card {
    flex: 1;
  }
}
