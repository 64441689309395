.heroSection {
  margin: 100px;
  margin-top: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.heroSection_content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.hero-icon {
  display: block;
  width: 300px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 20px;
}

.contact-card {
  background-color: rgba(91, 99, 104, 0.178);
  border-radius: 8px;
  padding: 20px;
  margin: 15px;
  width: 250px;
  min-width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

@media only screen and (max-width: 900px) {
  .hero-icon {
    width: 220px;
  }
}

@media only screen and (max-width: 700px) {
  .heroSection {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .heroSection_content {
    width: 100%;
  }
  .hero-icon {
    display: none;
  }
}
