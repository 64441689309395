.contact {
  width: 100%;
  padding: 20px 20px;
  text-align: left;
}
.divider {
  width: 100%;
  height: 4px;
  background-color: rgba(91, 99, 104, 0.178);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.contact h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact p {
  font-size: 1.2rem;
  text-align: left;
}

.email-div {
  display: flex;
}

.link {
  display: inline; /* or inline-block */
  margin-top: 10px;
}

.link:hover {
  color: rgb(117, 185, 130);
}

.link:hover p:hover {
  color: rgb(117, 185, 130);
}

.link p {
  margin: 0px;
  margin-left: 10px;
}

@media only screen and (max-width: 900px) {
  .contact {
    width: 80%;
    margin: auto;
    flex-direction: column;
  }

  .contact-details {
    margin-bottom: 80px;
  }
}
