.footer {
  color: white;
  margin: 20px;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.9rem;
  text-align: right;
}
