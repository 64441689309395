@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap");

*,
body {
  padding: 0;
  margin: 0;
  color: rgb(235, 248, 254);
}

body {
  padding: 0;
  margin: auto;
  background-color: rgb(43, 47, 49);
  color: rgb(235, 248, 254);
  display: flex;
  flex-direction: column;
  max-width: 1400px;
}

.App {
  color: rgb(62, 64, 65);
  font-family: "Jost", sans-serif;
  height: 100%;
  max-width: 1400px;
}

p {
  margin-top: 10px;
  font-size: 18px;
}
h4 {
  font-size: 20;
  color: rgb(117, 185, 130);
}

h3 {
  color: rgb(117, 185, 130);
}
h1 {
  color: rgb(117, 185, 130);
  font-size: 28px;
}

button {
  width: fit-content;
  background-color: transparent;
  border: 2px solid rgb(127, 211, 142);
  padding: 12px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  outline: none;
  z-index: 5;
}
button:hover {
  background-color: rgb(127, 211, 142);
  color: rgba(44, 42, 42, 0.915);
}
